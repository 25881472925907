export const BaseUrl = "https://api.travelom.com";

export const AuthLink =
  "https://authin.travelom.com/login?client_id=1khn1sgbq33cq77jv93j8b6ql8&response_type=token&scope=email+openid+phone+profile&redirect_uri=https%3A%2F%2Fintranet.travelom.com";

export const Header = () => {
    const access_token = () => localStorage.getItem('access_token')
    const header = {
        Authorization: `Bearer ${access_token()}`,
        "Content-Type": "application/json"
    }
    return header
}