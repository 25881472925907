// assets
import { TagsOutlined } from '@ant-design/icons';

// icons
const icons = {
    TagsOutlined
};

const membership = {
    id: 'membership',
    title: 'Membership',
    type: 'group',
    children: [
        {
            id: 'membership',
            title: 'Membership',
            type: 'item',
            url: '/membership',
            icon: icons.TagsOutlined
        }
    ]
};

export default membership;
