//import { useTheme } from '@mui/material/styles';
import logo from 'assets/images/logo/logo.jpg';

// ==============================|| LOGO SVG ||============================== //
const Logo = () => {
    //const theme = useTheme();
    return (
        <>
            <img src={logo} alt="Travelom" width="80%" />
        </>
    );
};

export default Logo;