import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BaseUrl } from "constants/URLConstants";
import { Header } from "../../../constants/URLConstants"

const header: any = Header()
export const getMemberShipList = createAsyncThunk(
  "travel/membershipList",
  async (val: any) => {
    const response: any = await axios
      .get(`${BaseUrl}/member/memberships/${val}`, {'headers': header})
      .catch((error) => console.log(error));
    return response?.data || [];
  }
);

export const appMembershipSlice = createSlice({
  name: "appMembership",
  initialState: {
    list: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMemberShipList.fulfilled, (state, action) => {
      state.list = action?.payload;
    });
  },
});

export default appMembershipSlice.reducer;
