import { lazy } from 'react';

import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

const DashboardPage = Loadable(lazy(() => import('pages/dashboard')));
const Membership = Loadable(lazy(() => import('pages/membership/list')));
const MembershipForm = Loadable(lazy(() => import('pages/membership/form')));

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardPage />
        },
        {
            path: '/dashboard',
            element: <DashboardPage />
        },
        {
            path: '/membership',
            element: <Membership />
        },
        {
            path: '/newMembership',
            element: <MembershipForm />
        },
        {
            path: '/updatemembership/:id',
            element: <MembershipForm />
        }
        
    ]
};

export default MainRoutes;
