import { useRoutes } from "react-router-dom";

import MainRoutes from "./MainRoutes";
import { AuthLink } from '../constants/URLConstants'

const token = () => localStorage.getItem("access_token");
export default function ThemeRoutes() {
  const location = window?.location?.hash;
  const handleAuthentication = () => {
    if (location?.toString()?.split("&")[1]?.split("=")[0] === "access_token") {
      localStorage.setItem(
        "access_token",
        location?.toString()?.split("&")[1]?.split("=")[1]
      );
    } else if (!token()) {
      window.location.replace(AuthLink);
    }
  };
  handleAuthentication();
  return useRoutes([MainRoutes]);
}
